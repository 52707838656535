import {NextSeo} from 'next-seo';
import * as React from 'react';

import {Video} from '@/lib/videos';

import DemoVideoSection from '@/components/landing/DemoVideoSection';
import FAQSection from '@/components/landing/FAQSection';
import FeaturesSection, {Feature} from '@/components/landing/FeaturesSection';
import GetStartedSection from '@/components/landing/GetStartedSection';
import HeroSection from '@/components/landing/HeroSection';
import TestimonialsSection from '@/components/landing/TestimonialsSection';
import UseCasesSection from '@/components/landing/UseCasesSection';
import Layout from '@/components/layout/Layout';
import {SITE_URL} from '@/lib/constants';

import {COPY} from '@/content/copy';

const __COPY__ = COPY[1];

const HomePage: React.FC<{ videos: Video[] }> = () => {
  return (
    <>
      <NextSeo
        title={__COPY__.title}
        description={__COPY__.description}
        openGraph={{
          title: __COPY__.title,
          description: __COPY__.description,
          url: `${SITE_URL}`,
          images: [
            {
              url: SITE_URL,
              width: 1200,
              height: 628,
              alt: 'ClipFM',
            },
          ],
          site_name: 'ClipFM',
        }}
      />
      <Layout>
        <main>
          <HeroSection
            titleTop={__COPY__.hero_title}
            subtitle={__COPY__.hero_subtitle}
          />
          <DemoVideoSection/>
          <UseCasesSection/>
          <FeaturesSection>
            <Feature
              name='Spend less time editing'
              description="Time is your most valuable asset. Simply upload a video and we'll deliver you ready-to-post clips within minutes."
              image='/images/save-time.png'
              alt='Hero image'
            />
            <Feature
              name='Grow your audience'
              description='Clips are a great way to get discovered by new listeners.'
              image='/images/grow-audience-2.png'
              alt='Hero image'
              flipped
            />
            <Feature
              name='Repurpose past videos'
              description='Repackage your existing videos instead of always needing to come up with new content.'
              image='/images/reuse-content.png'
              alt='Hero image'
            />
            <Feature
              name='Spend less money'
              description='Pay less than 10% the price of what it costs to hire an editor.'
              image='/images/lower-price-4.png'
              alt='Hero image'
              flipped
            />
          </FeaturesSection>
          <TestimonialsSection/>
          <FAQSection/>
          <GetStartedSection/>
        </main>
      </Layout>
    </>
  );
};

export default HomePage;
