import clsx from 'clsx';
import Link from 'next/link';
import * as React from 'react';

import styles from './UseCasesSection.module.scss';

import { CUSTOMER_PERSONAS } from '@/content/copy';

const ROTATIONS = [-1, 0.5, -0.5, 0.75, -0.9];

/**
 * TODO: Track clicks
 */
const UseCasesSection: React.FC<{title?: string}> = ({title}) => (
  <section className='mt-12 space-y-8 px-6 text-center'>
    <h2>{title ? title : "Built for professional teams & creators"}</h2>
    <div className='flex flex-col space-y-4 space-x-0 lg:block lg:flex-row lg:space-y-0 lg:space-x-4'>
      {CUSTOMER_PERSONAS.map((persona, index) => (
        <Link
          key={persona.name}
          href={`/for-${persona.slug}`}
          className={clsx(styles.persona, 'group')}
          style={
            {
              '--persona-color': persona.color,
              '--persona-rotation': `${ROTATIONS[index] || 0}deg`,
            } as any
          }
        >
          <div>
            <persona.icon className='inline' />
            {/* <ArrowRightIcon className='hidden h-4 transition-all group-hover:inline' /> */}
          </div>
          <span>{persona.name}</span>
        </Link>
      ))}
    </div>
  </section>
);

export default UseCasesSection;
