/* eslint-disable @next/next/no-img-element */
import clsx from 'clsx';
import * as React from 'react';

import styles from '@/styles/DemoVideoSection.module.scss';

import Waves from '@/components/Waves';

const DemoVideoSection: React.FC = () => {
  return (
    <Waves className={styles['video-contain']}>
      <iframe
        className={clsx(styles.video, 'max-layout')}
        src='https://www.youtube.com/embed/qvFDsdYrJmk'
        title='YouTube video player'
        frameBorder='0'
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
        allowFullScreen
      ></iframe>
    </Waves>
  );
};

export default DemoVideoSection;
