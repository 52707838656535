import * as React from 'react';

import styles from '@/styles/TestimonialsSection.module.scss';

const TestimonialsSection: React.FC = () => (
  <section className={styles.testimonials}>
    <div className={styles.testimonialsContent}>
      <div className='mx-auto max-w-[900px] space-y-6 px-5 md:px-20 text-center'>
        <a
          href='https://www.youtube.com/@SydneyCampos'
          className='group block'
          target='_blank'
          rel='noreferrer'
        >
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img
            className='mb-2 inline-block h-16 w-16 rounded-full bg-cover'
            src='/images/sydney.png'
            alt='Sydney Campos'
          />
          <div className='text-3xl font-bold group-hover:underline'>
            Sydney Campos
          </div>
          <div>Visionary Souls Podcast</div>
        </a>
        <p className='text-2xl md:text-3xl'>
          “What an AWESOME TOOL! I hired a team to do this for me for $500 and
          they made the WORST videos... I just made 9 clips that are way more
          effective and clean in 20 mins. So grateful this came into my
          awareness.”
        </p>
      </div>
    </div>
  </section>
);

export default TestimonialsSection;
