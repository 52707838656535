/* eslint-disable @next/next/no-img-element */
import clsx from 'clsx';
import * as React from 'react';
import { FC } from 'types';

import styles from './Waves.module.scss';

const Waves: FC<{ className?: string }> = ({ children, className }) => {
  return (
    <section className={clsx(styles.container)}>
      <img
        src='/theme/waves/waves-top.png'
        className={styles.waves}
        draggable='false'
        alt='wave'
        title='wave'
      />
      <div className={clsx(styles.content, className)}>{children}</div>
      <img
        src='/theme/waves/waves-bot.png'
        className={styles.waves}
        draggable='false'
        alt='wave'
        title='wave'
      />
    </section>
  );
};

export default Waves;
